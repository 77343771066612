body
{
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body
{
    margin: 0;
    padding: 0;

    /* Safari fix */
    overflow-x: hidden;
}

/* heebo-300 - latin */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 300;
    src: url('../font/heebo-v21-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../font/heebo-v21-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../font/heebo-v21-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
        url('../font/heebo-v21-latin-300.woff') format('woff'), /* Modern Browsers */
        url('../font/heebo-v21-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../font/heebo-v21-latin-300.svg#Heebo') format('svg'); /* Legacy iOS */
}

/* heebo-regular - latin */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: url('../font/heebo-v21-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../font/heebo-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../font/heebo-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('../font/heebo-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
        url('../font/heebo-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../font/heebo-v21-latin-regular.svg#Heebo') format('svg'); /* Legacy iOS */
}

/* heebo-500 - latin */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    src: url('../font/heebo-v21-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../font/heebo-v21-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../font/heebo-v21-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
        url('../font/heebo-v21-latin-500.woff') format('woff'), /* Modern Browsers */
        url('../font/heebo-v21-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../font/heebo-v21-latin-500.svg#Heebo') format('svg'); /* Legacy iOS */
}

/* heebo-600 - latin */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 600;
    src: url('../font/heebo-v21-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
            url('../font/heebo-v21-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../font/heebo-v21-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
            url('../font/heebo-v21-latin-600.woff') format('woff'), /* Modern Browsers */
            url('../font/heebo-v21-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../font/heebo-v21-latin-600.svg#Heebo') format('svg'); /* Legacy iOS */
}

/* heebo-800 - latin */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 800;
    src: url('../font/heebo-v21-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../font/heebo-v21-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../font/heebo-v21-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
        url('../font/heebo-v21-latin-800.woff') format('woff'), /* Modern Browsers */
        url('../font/heebo-v21-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../font/heebo-v21-latin-800.svg#Heebo') format('svg'); /* Legacy iOS */
}

/* Bootstrap 5 tweak: do not underline links unless hovered over */
a
{
    color: #298ce8;
    text-decoration: initial;
}

a:hover
{
    cursor: pointer;
    color: #0056b3;

    transition: all .25s ease-out;
}

:target
{
    scroll-margin-top: 72px;
}

.page
{
    min-height: 100vh;
}