.navbar-container
{
    padding-top: 0px;
    padding-bottom: 0px;

    min-height: 72px;
}

.navbar-item
{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    
    height: 100%;
    margin-left: 32px;
    margin-right: 0px;
    
    color: #000000;
    
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    text-align: center;

    border-top: 3px solid #FFFFFF;
    border-bottom: 3px solid #FFFFFF;
    
    transition: all .25s ease-out;
}

/* XS, SM, MD */
@media (max-width: 991.98px) 
{
    .navbar-item
    {
        margin-left: 0px;

        padding-top: 6px;
        padding-bottom: 6px;
    }
}

.navbar-item:hover
{
    color: #298ce8;

    border-top: 3px solid #FFFFFF;
    border-bottom: 3px solid #298ce8;

    text-decoration: none;
    cursor: pointer;
    
    transition: all .25s ease-out;
}

.navbar-item-multilanguage-container
{
    position: relative;
    display: flex;
    align-items: center;

    min-height: 72px;

    margin-right: -12px;

    background-color: #ffffff;
}

/* <=SM 767.98px */
@media (max-width: 991.98px)
{
    .navbar-item-multilanguage-container
    {
        margin-left: 0px;
    }
}

.navbar-item-multilanguage-item-container
{
    padding-left: 12px;
    padding-right: 12px;

    height: 72px;
}

.navbar-item-multilanguage-container:hover .navbar-item-multilanguage-item-container
{
    background-color: #f5f5f5;
}

.navbar-item-multilanguage-item
{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    height: 100%;
    
    color: #000000;

    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    
    transition: all .25s ease-out;
}

.navbar-item-multilanguage-flag-nl
{
    display: inline-block;

    width: 18px;
    height: 12px;
    margin-right: 5px;
    margin-bottom: 1px;

    background-image: url('/public/image/icon_flag_nl.svg');
    background-repeat: no-repeat;
    background-size: 18px 12px;
}

.navbar-item-multilanguage-flag-en
{
    display: inline-block;

    width: 18px;
    height: 12px;
    margin-right: 5px;
    margin-bottom: 1px;

    background-image: url('/public/image/icon_flag_en.svg');
    background-repeat: no-repeat;
    background-size: 18px 12px;
}

.navbar-item-multilanguage-dropdown
{
    display: none;
    position: absolute;
    z-index: 1;

    left: 0px;
    top: 72px;
}

/* <=SM */
@media (max-width: 767.98px)
{
    .navbar-item-multilanguage-dropdown
    {
        left: -15px;
    }
}

.navbar-item-multilanguage-container:hover .navbar-item-multilanguage-dropdown
{
    display: block;

    background-color: #f5f5f5;
}

.navbar-item-multilanguage-dropdown-item
{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    font-size: 18px;
    justify-content: left;
    white-space: nowrap;
    
    height: 48px;
    margin-left: 12px;
    margin-right: 12px;

    border-top: 3px solid #f5f5f5;
    border-bottom: 3px solid #f5f5f5;

    color: #000000;
}

.navbar-item-multilanguage-dropdown-item:hover
{
    color: #298ce8;

    border-top: 3px solid #f5f5f5;
    border-bottom: 3px solid #298ce8;

    text-decoration: none;
    cursor: pointer;
    
    transition: all .25s ease-out;
}

.navbar-item-partnerlogin-container
{
    position: relative;
    display: flex;
    align-items: center;

    min-height: 72px;

    margin-left: 20px;
    margin-right: -12px;

    background-color: #ffffff;
}

.navbar-item-partnerlogin-item-container
{
    padding-left: 12px;
    padding-right: 12px;

    height: 72px;
}

.navbar-item-partnerlogin-container:hover .navbar-item-partnerlogin-item-container
{
    background-color: #f5f5f5;
}

.navbar-item-partnerlogin-item
{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    height: 100%;
    
    color: #000000;

    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    
    transition: all .25s ease-out;
}

.navbar-item-partnerlogin-dropdown
{
    display: none;
    position: absolute;
    z-index: 1;

    right: 0px;
    top: 72px;
}

.navbar-item-partnerlogin-container:hover .navbar-item-partnerlogin-dropdown
{
    display: block;

    background-color: #f5f5f5;
}

.navbar-item-partnerlogin-dropdown-item
{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    font-size: 18px;
    justify-content: left;
    white-space: nowrap;
    
    height: 48px;
    margin-left: 12px;
    margin-right: 12px;

    border-top: 3px solid #f5f5f5;
    border-bottom: 3px solid #f5f5f5;

    color: #000000;
}

.navbar-item-partnerlogin-dropdown-item:hover
{
    color: #298ce8;

    border-top: 3px solid #f5f5f5;
    border-bottom: 3px solid #298ce8;

    text-decoration: none;
    cursor: pointer;
    
    transition: all .25s ease-out;
}

.navbar-justify-right
{
    justify-content: right;
}

/* Full height */
.navbar-nav
{
    min-height: 72px;
}

.navbar-section
{
    background: #ffffff;
}

.navbar-section-spacing
{
    margin-top: 72px;
}

.navbar-title
{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    height: 100%;

    color: #000000;
    
    font-family: Heebo;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
}

.navbar-title:hover
{
    color: #000000;
}

.navbar-title-trademark
{
    padding-top: 2px;
    padding-left: 1px;
    
    font-family: Heebo;
    font-size: 10px;
    font-weight: 500;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
}

/* Used by Bootstrap */
.navbar-toggler
{
    border-style: solid;
    border-width: 1px;
    border-color: #000000;
    border-radius: 8px;

    /* Fix for button displacement on dropdown open */
    height: 40px;
    margin-top: 16px;
    margin-bottom: 16px;
}