.panel-container-mobile
{
    display: flex;
    flex-flow: column;

    margin-top: 24px;
    margin-bottom: 0px;
    margin-left: -12px;
    margin-right: -12px;

    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 12px;
    padding-right: 12px;

    height: 540px;
    
    background-color: #ffffff;
}

.panel-container-mobile-alt
{
    display: flex;
    flex-flow: column;

    margin-top: 24px;
    margin-bottom: 0px;
    margin-left: -12px;
    margin-right: -12px;

    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 12px;
    padding-right: 12px;

    height: 540px;
    
    background-color: #000000;
}

.panel-container-desktop
{
    display: flex;
    flex-flow: column;

    margin-bottom: 24px;
    padding: 24px;

    height: 540px;

    background-color: #ffffff;
}

.panel-container-desktop-alt
{
    display: flex;
    flex-flow: column;

    margin-bottom: 24px;
    padding: 24px;

    height: 540px;

    background-color: #000000;
}

.panel-header
{
    margin-bottom: 20px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    text-align: center;

    color: #a6a6a6;
}

.panel-image-contain
{
    margin-top: 30px;

    width: 100%;
    height: 100%;
    
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-image-shadow
{
    position: relative;
    object-fit: cover;
    
    margin-top: 30px;
    
    overflow: hidden;

    border-radius: 8px;

    box-shadow:  0 8px 16px #0000001c;
}

.panel-notes-mobile
{
    padding-top: 8px;
    padding-bottom: 0px;
    
    margin-bottom: 0px;
    margin-left: -12px;

    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    text-align: left;

    color: #939393;
}

.panel-notes-desktop
{
    padding-top: 8px;
    padding-bottom: 0px;

    margin-top: -10px;
    margin-bottom: 0px;
    margin-left: -16px;

    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    text-align: left;

    color: #939393;
}

.panel-profile-container-mobile
{
    display: flex;
    flex-flow: column;

    margin-top: 12px;
    margin-bottom: 0px;
    margin-left: -12px;
    margin-right: -12px;

    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 12px;
    padding-right: 12px;
    
    background-color: #ffffff;
}

.panel-profile-container-desktop
{
    display: flex;
    flex-flow: column;

    margin-bottom: 24px;
    padding: 24px;

    background-color: #ffffff;
}

.panel-profile-subtitle-mobile
{
    font-size: 22px;
    line-height: 30px;
    font-weight: 500;

    color: #a6a6a6;
}

.panel-profile-subtitle-desktop
{
    font-size: 22px;
    line-height: 30px;
    font-weight: 500;

    color: #999999;
}

.panel-profile-title-mobile
{
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;

    color: #000000;
}

.panel-profile-title-desktop
{
    font-size: 30px;
    line-height: 36px;
    font-weight: 600;

    color: #000000;
}

.panel-recommendation-container-mobile
{
    display: flex;
    flex-flow: column;

    margin-top: 12px;
    margin-bottom: 0px;
    margin-left: -12px;
    margin-right: -12px;

    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 12px;
    padding-right: 12px;
    
    background-color: #ffffff;
}

.panel-recommendation-container-desktop
{
    display: flex;
    flex-flow: column;
    
    margin-bottom: 24px;
    padding: 24px;

    background-color: #ffffff;
}

.panel-recommendation-header-container-mobile
{
    display: flex;

    margin-left: -12px;
    margin-right: -12px;
    padding-left: 12px;
    padding-right: 12px;

    background-color: #000000;
    background-image: url('/public/video/banner_predict.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 40% 50%;

    align-items: center;

    height: 380px;
    margin-bottom: 24px;
    
    overflow: hidden;
    position: relative;
}

.panel-recommendation-header-container-desktop
{
    display: flex;

    background-color: #333;
    background-position: top;
    background-size: cover;

    align-items: center;

    height: 320px;
    margin-bottom: 24px;
    
    overflow: hidden;
    position: relative;
}

.panel-recommendation-header-overlay
{
    position: absolute;

    width: 100%;
    
    padding-left: 24px;
    padding-right: 24px;
}

/* XS, SM */
@media (max-width: 767.98px)
{
    .panel-recommendation-header-overlay
    {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.panel-recommendation-header-title-mobile
{
    width: calc(100% - 24px);

    margin: 0;
    margin-bottom: 0px;

    color: #ffffff;

    font-size: 24px;
    line-height: 29px;

    font-weight: 700;
    -webkit-font-smoothing: antialiased;
}

.panel-recommendation-header-title-tablet
{
    margin: 0;
    margin-bottom: 0px;

    color: #ffffff;

    font-size: 42px;
    line-height: 46px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
}

.panel-recommendation-header-title-desktop
{
    margin: 0;
    margin-bottom: 0px;

    color: #ffffff;

    font-size: 54px;
    line-height: 60px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
}

.panel-recommendation-header-subtitle-mobile
{
    width: calc(100% - 24px);
    
    margin-top: 18px;
    margin-bottom: 0px;

    color: #ffffff;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
}

.panel-recommendation-header-subtitle-desktop
{
    width: 100%;

    margin-top: 18px;
    margin-bottom: 0px;

    color: #ffffff;

    font-size: 30px;
    line-height: 36px;
    font-weight: 600;
}

.panel-recommendation-header-video
{
    position: absolute;
    z-index: 0;
    object-fit: cover;
    
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.panel-recommendation-image
{
    margin-top: 14px;

    border-radius: 8px;

    box-shadow: 0 8px 16px #0000001c;
}

.panel-section
{
    padding-top: 64px;
    
    background-color: #f5f5f5;
}

.panel-section-last
{
    padding-top: 64px;
    padding-bottom: 64px;
    
    background-color: #f5f5f5;
}

.panel-services-container-mobile
{
    display: flex;
    flex-flow: column;

    margin-top: 12px;
    margin-bottom: 0px;
    margin-left: -12px;
    margin-right: -12px;

    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 12px;
    padding-right: 12px;
    
    background-color: #ffffff;
}

.panel-services-container-desktop
{
    display: flex;
    flex-flow: column;

    margin-bottom: 24px;
    padding: 24px;

    background-color: #ffffff;
}

.panel-services-title
{
    margin-top: 16px;
    padding-bottom: 8px;
    
    font-size: 22px;
    line-height: 30px;
    font-weight: 500;

    color: #000000;

    border-bottom-color: #000000;
    border-bottom-style: solid;
    border-bottom-width: 2px;
}

.panel-text
{
    padding-top: 8px;
    padding-bottom: 0px;
    margin-bottom: 0px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;

    color: #000000;
}

.panel-text-alt
{
    padding-top: 8px;
    padding-bottom: 0px;
    margin-bottom: 0px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;

    color: #ffffff;
}

.panel-text-quote
{
    padding-top: 16px;
    margin-bottom: -16px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;

    color: #000000;
}