.slideshow-container
{   
    height: calc(100vh - 300px);
    max-height: 750px;
    min-height: 580px;

    z-index: 1;
}

.slideshow-container-overlay
{
    position: absolute;

    width: 100%;
    height: calc(100vh - 300px);
    max-height: 750px;
    min-height: 580px;

    z-index: 3;
}

.slideshow-background-architecture
{
    background-color: #000000;
    background-image: url('/public/image/slideshow_architecture.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.slideshow-background-businessplatform
{
    background-color: #f6f6f6;
    background-image: url('/public/image/slideshow_businessplatform.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.slideshow-background-configurator
{
    background-color: #e5e5e5;
    background-image: url('/public/image/slideshow_keuzeconfigurator.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.slideshow-background-curves
{
    background-color: #000000;
    background-image: url('/public/image/slideshow_curves.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.slideshow-background-customsoftware
{
    background-color: #000000;
    background-image: url('/public/image/slideshow_customsoftware.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.slideshow-background-machinelearning
{
    background-color: #000000;
    background-image: url('/public/image/slideshow_machinelearning.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/* XS, SM */
@media (max-width: 767.98px)
{
    .slideshow-background-architecture
    {
        background-image: url('/public/image/slideshow_architecture_mobile.jpg');
        background-position: 25% 50%;
    }

    .slideshow-background-businessplatform
    {
        background-image: url('/public/image/slideshow_businessplatform_mobile.jpg');
        background-position: 50% 50%;
    }

    .slideshow-background-businessplatform
    {
        background-image: url('/public/image/slideshow_businessplatform_mobile.jpg');
        background-position: 60% 50%;
    }

    .slideshow-background-configurator
    {
        background-image: url('/public/image/slideshow_keuzeconfigurator_mobile.jpg');
        background-position: 20% 50%;
    }
    
    .slideshow-background-curves
    {
        background-image: url('/public/image/slideshow_curves_mobile.jpg');
        background-position: 50% 50%;
    }

    .slideshow-background-customsoftware
    {
        background-image: url('/public/image/slideshow_customsoftware_mobile.jpg');
        background-position: 50% 50%;
    }

    .slideshow-background-machinelearning
    {
        background-image: url('/public/image/slideshow_machinelearning_mobile.jpg');
        background-position: 50% 50%;
    }
}

/* MD */
@media (min-width: 768px) and (max-width: 991.98px)
{
    .slideshow-background-businessplatform
    {
        background-position: 45% 50%;
    }
}

.slideshow-button-primary
{
    display: inline-block;
    
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 12px;
    margin-right: 16px;

    background-color: #298ce8;
    color: #ffffff;

    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.125);

    border-color: #298ce8;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
}

.slideshow-button-primary:hover
{
    background-color: #3d9bf4;
    color: #ffffff;

    text-decoration: none;

    border-color: #298ce8;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    cursor: pointer;
}

.slideshow-button-primary-alt
{
    display: inline-block;

    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 12px;
    margin-right: 16px;

    color: #298ce8;

    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.125);

    border-color: #298ce8;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
}

.slideshow-button-primary-alt:hover
{
    background-color: #3d9bf4;
    color: #ffffff;

    text-decoration: none;

    border-color: #298ce8;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    cursor: pointer;
}

.slideshow-button-businessplatform
{
    display: inline-block;

    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 12px;
    margin-right: 16px;

    background-color: #91cf31;
    color: #ffffff;

    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.125);

    border-color: #91cf31;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
}

.slideshow-button-businessplatform:hover
{
    background-color: #9ddd3e;
    color: #ffffff;

    text-decoration: none;

    border-color: #91cf31;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    cursor: pointer;
}

.slideshow-button-company
{
    display: inline-block;

    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 12px;
    margin-right: 16px;

    background-color: #f68611;
    color: #ffffff;

    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.125);

    border-color: #f68611;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
}

.slideshow-button-company:hover
{
    background-color: #ff8c12;
    color: #ffffff;

    text-decoration: none;

    border-color: #f68611;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    cursor: pointer;
}

.slideshow-button-company-alt
{
    display: inline-block;

    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 12px;
    margin-right: 16px;

    color: #f68611;

    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    
    border-color: #f68611;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
}

.slideshow-button-company-alt:hover
{
    background-color: #ff8c12;
    color: #ffffff;

    text-decoration: none;

    border-color: #f68611;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    cursor: pointer;
}

.slideshow-button-configurator
{
    display: inline-block;

    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 12px;
    margin-right: 16px;

    background-color: #8cc63f;
    color: #ffffff;

    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.125);

    border-color: #8cc63f;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
}

.slideshow-button-configurator:hover
{
    background-color: #95cc4d;
    color: #ffffff;

    text-decoration: none;

    border-color: #8cc63f;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    cursor: pointer;
}

.slideshow-button-customsoftware
{
    display: inline-block;

    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 12px;
    margin-right: 16px;

    background-color: #da291c;
    color: #ffffff;

    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.125);

    border-color: #da291c;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
}

.slideshow-button-customsoftware:hover
{
    background-color: #f72819;
    color: #ffffff;

    text-decoration: none;

    border-color: #da291c;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    cursor: pointer;
}

.slideshow-button-linkedin
{
    display: inline-block;

    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 12px;
    margin-right: 16px;

    background-color: #0b66c2;
    color: #ffffff;

    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.125);

    border-color: #0b66c2;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
}

.slideshow-button-linkedin:hover
{
    background-color: #1572d0;
    color: #ffffff;

    text-decoration: none;

    border-color: #0b66c2;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    cursor: pointer;
}

.slideshow-link-external-icon
{
    display: inline-block;

    margin-left: 7px;

    width: 12px;
    height: 12px;
    
    background-image: url("/public/image/icon_external_link.svg");
    background-repeat: no-repeat;
    background-size: contain;
}

.slideshow-category
{
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    text-align: left;
}

.slideshow-content-container
{
    display: flex;
    align-items: center;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    height: calc(100% - 126px);  
}

/* LG and up */
@media (min-width: 992px)
{
    .slideshow-content-container
    {
        height: calc(100% - 172px);
    }
}

@keyframes slideshow-fade-1
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-1
{
    opacity: 1.0; 

    animation-name: slideshow-fade-1;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-1
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-2
{
    opacity: 1.0;

    animation-name: slideshow-fade-2;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-2
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-3
{
    opacity: 1.0;

    animation-name: slideshow-fade-3;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-3
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-4
{
    opacity: 1.0;

    animation-name: slideshow-fade-4;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-4
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-5
{
    opacity: 1.0;

    animation-name: slideshow-fade-5;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-5
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-6
{
    opacity: 1.0;

    animation-name: slideshow-fade-6;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-6
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-7
{
    opacity: 1.0;

    animation-name: slideshow-fade-7;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-7
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-8
{
    opacity: 1.0;

    animation-name: slideshow-fade-8;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-8
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-9
{
    opacity: 1.0;

    animation-name: slideshow-fade-9;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-9
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-10
{
    opacity: 1.0;

    animation-name: slideshow-fade-6;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-10
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-image
{
    border: 1px solid #ffffff;
    border-radius: 24px;

    box-shadow: 0 10px 32px #00000017;
}

.slideshow-section
{
    position: relative;

    overflow: hidden;

    height: calc(100vh - 300px);
    max-height: 750px;
    min-height: 580px;

    background-color: #ffffff;
    background-position: center top;
    background-size: cover;

    padding-bottom: 48px;
}

.slideshow-text
{
    margin-top: 12px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;
}

.slideshow-thumbnail
{
    height: 100px;
    margin-top: 24px;
    margin-bottom: 48px;

    text-overflow: ellipsis;
    overflow: hidden;

    transition: color background-color 0.25s linear;
    -moz-transition: color background-color 0.25s linear;
    -webkit-transition: color background-color 0.25s linear;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.slideshow-thumbnail:hover
{
    cursor: pointer;
}

.slideshow-thumbnail-container
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    column-gap: 15px;
    flex-wrap: nowrap;
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
}

.slideshow-thumbnail-flex
{
    width: 100%;
}

.slideshow-thumbnail-gradient-white
{
    position: absolute;
    bottom: 0px;
    width: 100%;

    background: rgb(255,255,255);
    background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}

.slideshow-thumbnail-gradient-gray
{
    position: absolute;
    bottom: 0px;
    width: 100%;

    background: #e5e5e5;
    background: -moz-linear-gradient(180deg, rgba(245,245,245,0) 0%, #e5e5e5 100%);
    background: -webkit-linear-gradient(180deg, rgba(245,245,245,0) 0%, #e5e5e5 100%);
    background: linear-gradient(180deg, rgba(245,245,245,0) 0%, #e5e5e5 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e5e5e5",endColorstr="#e5e5e5",GradientType=1);
}

.slideshow-thumbnail-gradient-black
{
    position: absolute;
    bottom: 0px;
    width: 100%;

    background: rgb(0,0,0);
    background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}

@keyframes slideshow-thumbnail-timer-animation-keyframes
{
    from
    {
        width: 0px;
    }

    to
    {
        width: 100%;
    }
}

.slideshow-thumbnail-timer-animation-primary
{
    height: 3px;

    background-color: #298ce8;

    animation-name: slideshow-thumbnail-timer-animation-keyframes;
    animation-duration: 15s;
    animation-timing-function: linear;
}

.slideshow-thumbnail-timer-animation-businessplatform
{
    height: 3px;

    background-color: #91cf31;

    animation-name: slideshow-thumbnail-timer-animation-keyframes;
    animation-duration: 15s;
    animation-timing-function: linear;
}

.slideshow-thumbnail-timer-animation-configurator
{
    height: 3px;

    background-color: #8cc63f;

    animation-name: slideshow-thumbnail-timer-animation-keyframes;
    animation-duration: 15s;
    animation-timing-function: linear;
}

.slideshow-thumbnail-timer-animation-customsoftware
{
    height: 3px;

    background-color: #da291c;

    animation-name: slideshow-thumbnail-timer-animation-keyframes;
    animation-duration: 15s;
    animation-timing-function: linear;
}

.slideshow-thumbnail-timer-white
{
    background-color: rgba(100, 100, 100, 0.4);
    
    height: 3px;
}

.slideshow-thumbnail-timer-gray
{
    background-color: rgba(100, 100, 100, 0.4);
    
    height: 3px;
}

.slideshow-thumbnail-timer-black
{
    background-color: rgba(255, 255, 255, 0.4);
    
    height: 3px;
}

/* XS, SM, MD */
@media (max-width: 991.98px)
{
    .slideshow-thumbnail-timer-animation-primary
    {
        height: 5px;
    }

    .slideshow-thumbnail-timer-animation-businessplatform
    {
        height: 5px;
    }

    .slideshow-thumbnail-timer-animation-configurator
    {
        height: 5px;
    }

    .slideshow-thumbnail-timer-animation-customsoftware
    {
        height: 5px;
    }

    .slideshow-thumbnail-timer-white
    {
        height: 5px;
    }

    .slideshow-thumbnail-timer-gray
    {
        height: 5px;
    }

    .slideshow-thumbnail-timer-black
    {
        height: 5px;
    }
}

.slideshow-thumbnail-category
{
    margin-top: 14px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    text-align: left;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
}

/* XS, SM */
@media (max-width: 767.98px)
{
    .slideshow-thumbnail-category
    {
        display: none !important;
    }
}

.slideshow-thumbnail-text
{
    margin-top: 12px;
    height: 46px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
}

/* XS, SM */
@media (max-width: 767.98px)
{
    .slideshow-thumbnail
    {
        height: 54px;
    }

    .slideshow-thumbnail-text
    {
        display: none !important;
    }
}

/* MD */
@media (min-width: 768px) and (max-width: 991.98px)
{
    .slideshow-thumbnail
    {
        height: 54px;
    }
}

.slideshow-title-mobile
{
    margin: 0;
    margin-top: 12px;
    margin-bottom: 0px;

    font-size: 42px;
    line-height: 46px;

    font-weight: 600;
    -webkit-font-smoothing: antialiased;
}

.slideshow-title-tablet
{
    margin: 0;
    margin-top: 12px;
    margin-bottom: 0px;

    font-size: 42px;
    line-height: 46px;
    
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
}

.slideshow-title-desktop
{
    margin: 0;
    margin-top: 12px;
    margin-bottom: 0px;

    font-size: 54px;
    line-height: 60px;
    
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
}