.element-text
{
    margin-top: 24px;

    color: #212529;

    text-decoration: none;
    font-size: 16px;
    line-height: 19px;
}

.element-title-h1-mobile
{
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;


    color: #000000;
}

.element-title-h1-desktop
{
    font-size: 30px;
    line-height: 36px;
    font-weight: 600;

    color: #000000;
}

.element-title-h2-mobile
{
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;


    color: #000000;
}

.element-title-h2-mobile-alt
{
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;


    color: #ffffff;
}

.element-title-h2-desktop
{
    font-size: 30px;
    line-height: 36px;
    font-weight: 600;

    color: #000000;
}

.element-title-h2-desktop-alt
{
    font-size: 30px;
    line-height: 36px;
    font-weight: 600;

    color: #ffffff;
}

.element-section
{
    padding-top: 64px;
    padding-bottom: 64px;
}