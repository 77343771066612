.status-section
{
    background-color: #252525;
}

.status-container
{
    display: flex;
    align-items: center;
    
    min-height: 32px;
}

.status-chevron
{
    display: inline-block;

    width: 8.5px;
    height: 8.5px;
    margin-right: 24px;
    margin-bottom: -1px;

    background-image: url("/public/image/icon_chevron_right.svg");
    background-repeat: no-repeat;
    background-size: 8.5px 8.5px;
}

.status-block-container
{
    display: inline-block;

    margin-left: 3px;
}

.status-block-live
{
    display: inline-block;

    width: 7px;
    height: 7px;
    margin-left: 2px;
    margin-bottom: 1px;

    background-image: url("/public/image/icon_block_green.svg");
    background-repeat: no-repeat;
    background-size: 7px 7px;
}

.status-block-live-wide
{
    display: inline-block;

    width: 28px;
    height: 7px;
    margin-left: 2px;
    margin-bottom: 1px;

    background-image: url("/public/image/icon_block_green_wide.svg");
    background-repeat: no-repeat;
    background-size: 28px 7px;
}

.status-text-container
{
    display: inline-block;

    margin-right: 24px;

    white-space: nowrap;
}

.status-text
{
    display: inline-block;
    
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;

    color: #939393;
}

.status-title
{
    display: inline-block;

    margin-right: 24px;
    
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;

    color: #939393;
}